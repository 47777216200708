.lia-separator {
  display: flex;
  align-items: center;
  column-gap: 10px;
  color: var(--lia-bs-text-muted);
  font-size: var(--lia-bs-font-size-sm);
  margin-bottom: 10px;
  user-select: none;
  cursor: default;

  &:before,
  &:after {
    content: '';
    flex: 1;
    border-bottom: 1px solid var(--lia-bs-border-color);
  }
}
